<template>
  <div>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <Dialog
        :dialog="dialog"
        :title="'Neue Aufgabe anlegen'"
        :values="tasksCreateFields"
        :successButton="'Erstellen'"
        :icon="'mdi-format-list-checks'"
        :headLines="[
            {text: 'Verknüpfungen', position: 4},
            ]"
        :cut="4"
        @hideDialog="hideDialog"
        @emitNewData="createTask"
        @createEntry="startCreateEntity($event)"
    ></Dialog>
    <CreateVendor
        :dialog="dialogVendor"
        @hideDialog="hideDialogVendor"
        @createVendor="vendorCreated"
    >
    </CreateVendor>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {error, msgObj, success} from "@/helper/helper";

const Message = () => import("@/components/generalUI/Message");
const Dialog = () => import("@/components/dialog/FormDialogNew");
const CreateVendor = () => import("@/components/dialog/createDialogs/CreateVendor");

export default {
  name: "CreateWedding",
  components: {
    Dialog,
    Message,
    CreateVendor
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('wedding', {
      weddings: 'weddingsForSelection',
    }),
    ...mapGetters('vendor', {
      vendors: 'vendorsForSelection',
      createdVendorId: 'createdVendor'
    }),
  },
  data() {
    return {
      createFields: [],
      dialogVendor: false,
      tasksCreateFields: this.getTaskCreateFields(),
      message: msgObj(),
    }
  },
  props: {
    dialog: Boolean,
    weddingSelection: String
  },
  mounted() {
    this.$store.dispatch('vendor/getVendorsForSelection', {
      uid: this.user.id
    })
    this.$store.dispatch('wedding/getWeddingsForSelection', {
      uid: this.user.id
    })
    setTimeout(() => this.tasksCreateFields = [
      {
        title: 'Betreff*',
        value: '',
        id: 0,
        type: 'string',
        required: true
      },
      {
        title: 'Priorität',
        value: null,
        id: 1,
        items: ['Hoch', 'Mittel', 'Niedrig'],
        type: 'autoComplete',
        icon: '',
        entity: ''
      },
      {
        title: 'Fällig am',
        value: null,
        id: 2,
        type: 'date'
      },
      {
        title: 'Beschreibung',
        value: '',
        id: 3,
        type: 'string'
      },
      {
        title: 'Hochzeit',
        value: this.weddingSelection,
        id: 4,
        items: this.weddings,
        type: 'autoComplete',
        icon: '',
        entity: ''
      },

    ], 1000);
  },

  methods: {
    hideDialog() {
      this.$emit('hideDialog')
    },
    hideDialogVendor() {
      this.dialogVendor = false
    },
    startCreateCustomer() {
      this.dialogCustomer = true
    },
    vendorCreated() {
      this.$store.dispatch('vendor/getVendorsForSelection', {
        uid: this.user.id
      }).then(() => {
        setTimeout(() => this.tasksCreateFields = this.getTaskCreateFields(), 1000);
      })
    },
    getTaskCreateFields() {
      return [
        {
          title: 'Betreff*',
          value: '',
          id: 0,
          type: 'string',
          required: true
        },
        {
          title: 'Priorität',
          value: null,
          id: 1,
          items: ['Hoch', 'Mittel', 'Niedrig'],
          type: 'autoComplete',
          icon: '',
          entity: ''
        },
        {
          title: 'Fällig am',
          value: null,
          id: 2,
          type: 'date'
        },
        {
          title: 'Beschreibung',
          value: '',
          id: 3,
          type: 'string'
        },
        {
          title: 'Hochzeit',
          value: this.weddingSelection,
          id: 4,
          items: this.weddings,
          type: 'autoComplete',
          icon: '',
          entity: ''
        },
      ]
    },
    startCreateEntity(payload) {
      switch (payload.entity) {
        case 'customer':
          this.dialogCustomer = true
          break
        case 'wedding':
          this.dialogWedding = true
          break
        case 'vendor':
          this.dialogVendor = true
          break
      }
    },
    createTask(newValues) {
      let newTask = this.getTasksFromInput(newValues)
      if (newTask.subject !== '') {
        this.$store.dispatch('task/createTask', {
          newTask,
          uid: this.user.id
        }).then(() => {
              this.hideDialog()
              this.tasksCreateFields = this.getTaskCreateFields()
              this.message = success('Aufgabe wurde erfolgreich angelegt.')
            }
        ).catch((err) => {
          this.message = error(err)
          setTimeout(() => this.message.show = false, 5000);
        })
      } else {
        this.message = error("Bitte lege zuerst einen Betreff fest.")
      }
    },
    getTasksFromInput(newValues) {
      let newTasks = {
        subject: '',
        prio: '',
        date: null,
        description: '',
        status: 'Zu erledigen',
        weddingId: '',
        customerId: '',
        vendorId: ''
      }
      for (let value of newValues) {
        switch (value.title) {
          case 'Betreff*':
            newTasks.subject = value.value
            break;
          case 'Priorität':
            newTasks.prio = value.value
            break;
          case 'Fällig am':
            if (value.value) {
              let date = new Date(value.value)
              newTasks.date = date.getTime()
            } else {
              newTasks.date = null
            }
            break;
          case 'Beschreibung':
            newTasks.description = value.value
            break;
          case 'Hochzeit':
            newTasks.weddingId = value.value
            break;
          case 'Kunde':
            newTasks.customerId = value.value
            break;
          case 'Dienstleister':
            newTasks.vendorId = value.value
            break;
        }
      }
      return newTasks
    },

  },
}
</script>
